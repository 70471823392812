var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "3" } },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          attrs: {
            "max-width": "400",
            "max-height": "200",
            elevation: "0",
            outlined: ""
          }
        },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-12",
                  attrs: { color: _vm.item.color, size: "64" }
                },
                [_vm._v(" " + _vm._s(_vm.item.icon) + " ")]
              ),
              _c("v-row", { attrs: { align: "start" } }, [
                _c(
                  "div",
                  {
                    class: _vm.isSelectedItem
                      ? "text-caption blue--text text-uppercase mr-4"
                      : "text-caption grey--text text-uppercase mr-4"
                  },
                  [_vm._v(" " + _vm._s(_vm.item.status) + " ")]
                )
              ]),
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("click", _vm.item)
                      }
                    }
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.isSelectedItem
                                          ? "text-h3 font-weight-black blue--text"
                                          : "text-h3 font-weight-black"
                                      },
                                      [_vm._v(_vm._s(_vm.item.total_tickets))]
                                    ),
                                    _vm.isSelectedItem
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { color: "error", small: "" }
                                          },
                                          [
                                            _vm._v(
                                              " mdi-filter-remove-outline "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-2",
                                            attrs: {
                                              color: "primary",
                                              small: ""
                                            }
                                          },
                                          [_vm._v(" mdi-filter-menu-outline ")]
                                        )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        !_vm.isSelectedItem
                          ? _c("span", [
                              _vm._v(
                                "Filtrar chamados " + _vm._s(_vm.item.status)
                              )
                            ])
                          : _c("span", [_vm._v("Limpar Filtro")])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-sheet",
            { attrs: { color: "transparent" } },
            [
              _c("apexchart", {
                attrs: {
                  type: "area",
                  options: _vm.chartOptions,
                  series: _vm.series,
                  height: "100"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }